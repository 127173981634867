import styled, { css } from "styled-components"

import theme from "../../assets/themes/theme"

export const Heading = styled.h3`
  // text-transform: uppercase;
  // font-weight: bold;
  font-size: 1.1rem;
  margin-top: 0;
  margin-bottom: ${props => (props.noMarginBottom ? 0 : "1rem")};
  color: ${theme.colors.navbarblue};
  ${({ color }) =>
    color === "green" &&
    css`
      color: ${theme.colors.navbargreen};
    `}
  ${({ color }) =>
    color === "teal" &&
    css`
      color: ${theme.colors.navbarteal};
    `}
    ${({ color }) =>
    color === "blue" &&
    css`
      color: ${theme.colors.navbarblue};
    `}
    ${({ color }) =>
    color === "darkblue" &&
    css`
      color: ${theme.colors.navbardarkblue};
    `};

  /* color: ${({ color }) => (color ? `var(--${color})` : "var(--blue)")}; */
`

export const HeadingLink = Heading.withComponent("li")

export const LinkList = styled.ul`
  li {
    margin-bottom: 1rem;
  }
  li:last-of-type {
    margin-bottom: 0;
  }

  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)};
`

export const Icon = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 13px;
  background-color: ${theme.colors.navbarblue};
  opacity: 0.8;
  display: inline-block;
`

export const DropdownSection = styled.div`
  padding: ${theme.spacer};
  position: relative;
  z-index: 1;
`
